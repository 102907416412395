import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {getVideos as getAll} from "app/requests/episodes";
import {Loading} from "components/pages";
import {usePage} from "lib/hooks";
import {displayDate} from "lib/string";

function Videos({getRecords = getAll}) {
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState(null);

  usePage({heading: "Videos"});
  useEffect(() => {
    getRecords().then((data) => {
      setRecords([...data.records]);
      setLoading(false);
    });
  }, []);

  if(loading) { return <Loading />; }

  return (
    <div className="container-fluid">
      {records.length === 0 && <p>There are no videos</p>}
      <div className="row">
        {records.map((record) => (
          <div key={record.uid} className="col-md-3 mb-4 mt-1">
            <a className="d-block mb-1" href={record.youtube_link} rel="noreferrer" target="_blank">
              <img alt="Episode" className="img-fluid" src={record.image} />
            </a>
            <p className="font-weight-bold extra-small text-muted text-uppercase">Published on {displayDate(record.published_at)}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

Videos.propTypes = {getRecords: PropTypes.func};

export default Videos;
