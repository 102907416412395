import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {createNotification} from "app/actions/notifications";
import {setUser} from "app/actions/user";
import {create as createSession} from "app/requests/session";
import {create as createUser} from "app/requests/user";
import useAppContext from "lib/hooks/use-app-context";
import {withoutBlankValues} from "lib/object";

export default function User() {
  const {dispatch} = useAppContext();
  const [session, setSession] = useState({email: ""});
  const [show, setShow] = useState(false);
  const [user, setChanges] = useState({
    email: "",
    newsletter_notifications: true,
    post_notifications: true
  });
  const navigate = useNavigate();
  const onChange = ({target: {checked, name, type, value}}) => (
    setChanges({...user, [name]: type === "checkbox" ? checked : value})
  );
  const onLogin = (e) => {
    e.preventDefault();

    createSession({user: withoutBlankValues(session)}).then((data) => {
      dispatch(createNotification({
        content: data.message,
        type: data.success ? "primary" : "danger"
      }));

      if(data.location) { navigate(data.location); }
    });
  };
  const onSessionChange = ({target: {checked, name, type, value}}) => (
    setSession({...session, [name]: type === "checkbox" ? checked : value})
  );
  const onShow = () => {
    setSession({email: user.email || ""});
    setShow(!show);
  };
  const onSubmit = (e) => {
    e.preventDefault();

    createUser({user: withoutBlankValues(user)}).then((data) => {
      dispatch(createNotification({
        content: data.message,
        type: data.success ? "primary" : "danger"
      }));

      if(data.location) { navigate(data.location); }
      if(data.user) { dispatch(setUser(data.user)); }
    });
  };

  return (
    <>
      <form className="borderless-form" onSubmit={onSubmit}>
        <div className="input-group mb-4">
          <input
            className="form-control py-4"
            id="user-email"
            name="email"
            onChange={onChange}
            placeholder="Email"
            required={true}
            type="email"
            value={user.email}
          />
        </div>
        <div className="custom-control custom-switch mb-4">
          <input
            checked={user.post_notifications}
            className="custom-control-input py-4"
            id="user-post-notifications"
            name="post_notifications"
            onChange={onChange}
            type="checkbox"
          />
          <label className="custom-control-label" htmlFor="user-post-notifications">Episode Notifications</label>
        </div>
        <div className="custom-control custom-switch mb-4">
          <input
            checked={user.newsletter_notifications}
            className="custom-control-input py-4"
            id="user-newsletter-notifications"
            name="newsletter_notifications"
            onChange={onChange}
            type="checkbox"
          />
          <label className="custom-control-label" htmlFor="user-newsletter-notifications">Newsletter Notifications</label>
        </div>
        <div className="form-group">
          <button className="btn btn-primary px-5" type="submit">Sign Up</button>
          <button className="btn btn-link btn-sm" onClick={onShow} type="button">Edit Subscription?</button>
        </div>
      </form>
      {show && (
        <form className="borderless-form" onSubmit={onLogin}>
          <p>Submit your email below to receive a link to edit your subscription settings.</p>
          <div className="input-group mb-4">
            <input
              className="form-control py-4"
              id="session-email"
              name="email"
              onChange={onSessionChange}
              placeholder="Email"
              required={true}
              type="email"
              value={session.email}
            />
          </div>
          <button className="btn btn-primary px-5" type="submit">Send</button>
        </form>
      )}
    </>
  );
}
