import PropTypes from "prop-types";
import ContentEditor from "components/helpers/form/content-editor";
import DateField from "components/helpers/form/date-field";
import FileField from "components/helpers/form/file-field";
import FormWithFiles from "components/helpers/form/with-files";

function AdminEpisodesForm({channels = null, onChange, onSubmit, value}) {
  return (
    <FormWithFiles onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="episode-audio">Audio</label>
            </div>
            <FileField id="episode-audio" name="audio" onChange={onChange} />
            {value("audio") && (
              <div className="input-group-append">
                <a className="btn btn-secondary" href={value("audio")} rel="noreferrer" target="_blank">Play</a>
              </div>
            )}
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="episode-author">Author</label>
            </div>
            <input
              className="form-control"
              id="episode-author"
              name="author"
              onChange={onChange}
              placeholder="Resa E Lewiss"
              required={true}
              type="text"
              value={value("author")}
            />
          </div>
          {!value("id") && (
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <label className="input-group-text" htmlFor="episode-channel-id">Channel</label>
              </div>
              <select
                className="custom-select"
                id="episode-channel-id"
                name="channel_id"
                onChange={onChange}
                required={true}
                value={value("channel_id")}
              >
                {channels.map((channel) => (
                  <option key={channel.id} value={channel.id}>{channel.title}</option>
                ))}
              </select>
            </div>
          )}
          <div className="mb-3">
            <ContentEditor
              id="episode-description"
              label="Description"
              name="description"
              onChange={onChange}
              value={value("description")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="episode-itunes-link">iTunes Link</label>
            </div>
            <input
              className="form-control"
              id="episode-itunes-link"
              name="itunes_link"
              onChange={onChange}
              placeholder="Link to Episode in iTunes"
              type="url"
              value={value("itunes_link")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="episode-spotify-link">Spotify Link</label>
            </div>
            <input
              className="form-control"
              id="episode-spotify-link"
              name="spotify_link"
              onChange={onChange}
              placeholder="Link to Episode in Spotify"
              type="url"
              value={value("spotify_link")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="episode-youtube-link">Youtube Link</label>
            </div>
            <input
              className="form-control"
              id="episode-youtube-link"
              name="youtube_link"
              onChange={onChange}
              placeholder="Link to Episode in Youtube"
              type="url"
              value={value("youtube_link")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="episode-kind">Kind</label>
            </div>
            <select
              className="form-control"
              id="episode-kind"
              name="kind"
              onChange={onChange}
              value={value("kind")}
            >
              <option value="Full">Full</option>
              <option value="Trailer">Trailer</option>
              <option value="Bonus">None</option>
            </select>
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="episode-published-at">Publish Date</label>
            </div>
            <DateField
              id="episode-published-at"
              name="published_at"
              onChange={onChange}
              value={value("published_at")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="episode-subtitle">Subtitle</label>
            </div>
            <input
              className="form-control"
              id="episode-subtitle"
              name="subtitle"
              onChange={onChange}
              placeholder="Witty and Insightful"
              required={true}
              type="text"
              value={value("subtitle")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="episode-title">Title</label>
            </div>
            <input
              className="form-control"
              id="episode-title"
              name="title"
              onChange={onChange}
              placeholder="The Visible Voices"
              required={true}
              type="text"
              value={value("title")}
            />
          </div>
          <div className="mb-3">
            <ContentEditor
              id="episode-transcript"
              label="Transcript"
              name="transcript"
              onChange={onChange}
              value={value("transcript")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="episode-image">Image</label>
            </div>
            <FileField id="episode-image" name="image" onChange={onChange} />
            {value("image") && <img alt="Episode" className="img-fluid mt-2" src={value("image")} />}
          </div>
          <div className="custom-control custom-switch text-center mb-3">
            <input
              checked={value("explicit", false)}
              className="custom-control-input"
              id="episode-explicit"
              name="explicit"
              onChange={onChange}
              type="checkbox"
            />
            <label className="custom-control-label" htmlFor="episode-explicit">Explicit</label>
          </div>
          <div className="mb-3">
            <ContentEditor
              id="episode-email-content"
              label="Email Content"
              name="email_content"
              onChange={onChange}
              value={value("email_content")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="episode-email-preview-text">Email Preview Text</label>
            </div>
            <input
              className="form-control"
              id="episode-email-preview-text"
              name="email_preview_text"
              onChange={onChange}
              placeholder="Check this out!"
              type="text"
              value={value("email_preview_text")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="episode-email-subject">Email Subject</label>
            </div>
            <input
              className="form-control"
              id="episode-email-subject"
              name="email_subject"
              onChange={onChange}
              placeholder="Visible Voices - Episode 100"
              type="text"
              value={value("email_subject")}
            />
          </div>
          <div className="form-group text-center">
            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
        </div>
      </div>
    </FormWithFiles>
  );
}

AdminEpisodesForm.propTypes = {
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired
  ),
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default AdminEpisodesForm;
