import moment from "moment";
import PropTypes from "prop-types";

function DateField({onChange, value: _value = null, ...props}) {
  const onDateChange = ({target: {name, value}}) => {
    onChange({target: {name, value: value ? moment(value).format() : ""}});
  };
  let value = _value;
  if(value) { value = moment(value).format(moment.HTML5_FMT.DATETIME_LOCAL); }

  return (
    <input
      className="form-control"
      onChange={onDateChange}
      type="datetime-local"
      value={value || ""}
      {...props}
    />
  );
}

DateField.propTypes = {onChange: PropTypes.func.isRequired, value: PropTypes.string};

export default DateField;
