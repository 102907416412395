import PropTypes from "prop-types";
import {queryString} from "lib/object";

function Button({disabled = false, icon, query = {}, to, updatePage}) {
  if(disabled) {
    return (
      <button className="btn btn-secondary disabled" type="button">
        <i className={`fas ${icon}`} />
      </button>
    );
  }

  const onClick = (e) => {
    e.preventDefault();

    updatePage(to);
  };

  return (
    <a className="btn btn-secondary" href={`${window.location.pathname}?${queryString({...query, page: to})}`} onClick={onClick}>
      <i className={`fas ${icon}`} />
    </a>
  );
}

Button.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  query: PropTypes.shape({}),
  to: PropTypes.number.isRequired,
  updatePage: PropTypes.func.isRequired
};

function Pagination({page, updatePage}) {
  if(page.pages <= 1) { return null; }

  const props = {query: {limit: page.limit, search: page.search}, updatePage};

  return (
    <div className="btn-group">
      <Button disabled={page.number <= 1} icon="fa-angle-double-left" to={1} {...props} />
      <Button disabled={page.number <= 1} icon="fa-angle-left" to={page.number - 1} {...props} />
      <button className="btn btn-secondary disabled" type="button">{page.number}</button>
      <Button disabled={page.number >= page.pages} icon="fa-angle-right" to={page.number + 1} {...props} />
      <Button disabled={page.number >= page.pages} icon="fa-angle-double-right" to={page.pages} {...props} />
    </div>
  );
}

Pagination.propTypes = {
  page: PropTypes.shape({
    limit: PropTypes.number,
    number: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    search: PropTypes.string,
    total: PropTypes.number.isRequired
  }).isRequired,
  updatePage: PropTypes.func.isRequired
};

export default Pagination;
