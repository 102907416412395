import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {getAll} from "app/requests/episodes";
import {ActionText} from "components/helpers";
import Pagination from "components/helpers/pagination";
import {Loading} from "components/pages";
import {usePage} from "lib/hooks";
import useQueryParams from "lib/hooks/use-query-params";
import {displayDate} from "lib/string";

function Transcripts({getEpisodes = getAll, prefix = ""}) {
  const params = useQueryParams();
  const initialPage = params.get("page");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({});
  const [pageNumber, setPageNumber] = useState(initialPage ? +initialPage : 1);

  usePage({heading: "Transcripts"});
  useEffect(() => {
    setLoading(true);
    getEpisodes({page: pageNumber}).then((data) => {
      setPage({...data});
      setLoading(false);
    });
  }, [pageNumber]);

  if(!page.records || loading) { return <Loading />; }

  return (
    <div className="container-fluid">
      <div className="row">
        {page.records.map((record) => (
          <div key={record.uid} className="col-md-3 mb-4 mt-1">
            <div className="font-weight-bold extra-small text-muted text-uppercase">
              Published on {displayDate(record.published_at)}
            </div>
            <div className="line-height-sm mt-0">
              <a className="text-black" href={`${prefix}/episodes/${record.uid}/transcript`}>{record.title}</a>
            </div>
            {record.transcript ? (
              <ActionText.Content html={record.transcript} truncate={{url: `${prefix}/episodes/${record.uid}/transcript`}} />
            ) : (
              <div>Not yet available</div>
            )}
          </div>
        ))}
      </div>
      <div className="text-center">
        <Pagination page={page} updatePage={setPageNumber} />
      </div>
    </div>
  );
}

Transcripts.propTypes = {
  getEpisodes: PropTypes.func,
  prefix: PropTypes.string
};

export default Transcripts;
