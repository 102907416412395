import PropTypes from "prop-types";
import {ActionText} from "components/helpers";
import {displayDate} from "lib/string";

function EpisodeTile({episode, prefix = ""}) {
  return (
    <>
      <a className="d-block mb-4" href={`${prefix}/episodes/${episode.uid}`}>
        <img alt="Episode" className="img-fluid" src={episode.image} />
      </a>
      <ActionText.Content html={episode.description} truncate={{url: `${prefix}/episodes/${episode.uid}`}} />
      <p className="font-weight-bold mt-1 extra-small text-muted text-uppercase">Published on {displayDate(episode.published_at)}</p>
    </>
  );
}

EpisodeTile.propTypes = {
  episode: PropTypes.shape({
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    published_at: PropTypes.string,
    uid: PropTypes.string.isRequired
  }).isRequired,
  prefix: PropTypes.string
};

export default EpisodeTile;
